import React from "react";
import { Link } from "react-router-dom";

import "./style.less";

// application footer
const Footer = () => {
  return (
    <>
      <footer className="layout-footer">
        <div className="footer-divider" />

        <div className="footer-copyright-div">© 2023 Syngenta</div>

        <div className="layout-footer-content">
          <Link to={"/unternehmen/impressum"} className="footer-link" target="_blank">
            Impressum
          </Link>
          <Link to={"/unternehmen/nutzungsbedingungen"} className="footer-link" target="_blank">
            Nutzungsbedingungen
          </Link>
          <Link to={"/unternehmen/datenschutz"} className="footer-link" target="_blank">
            Datenschutz
          </Link>
          <Link to={"/unternehmen/cookie-richtlinie"} className="footer-link" target="_blank">
            Cookie-Richtlinie
          </Link>
          <Link to={"https://www.syngenta.de"} className="footer-link" target="_blank">
            Syngenta
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;
