import React from "react";

import Loader from "@/components/loader/Loader";

import { IChartsType, ISelectedStationData } from "@/types/HomeTypes";

import RoundGreenIcon from "../../../../assets/round-green.svg";
import RoundDarkGreenIcon from "../../../../assets/round-dark-green.svg";
import RoundOrangeIcon from "../../../../assets/round-orange.svg";
import RoundYellowIcon from "../../../../assets/round-yellow.svg";
import RoundRedIcon from "../../../../assets/round-red.svg";
import RoundBlackIcon from "../../../../assets/round-black.svg";
import RoundBrownIcon from "../../../../assets/round-brown.svg";

import "./EpidemicPressureDevelopment.less";

const epidemicPressureLegends = [
  { key: "Sehr gering", icon: RoundGreenIcon },
  { key: "Gering", icon: RoundDarkGreenIcon },
  { key: "Hoch", icon: RoundOrangeIcon },
  { key: "Mittel", icon: RoundBrownIcon },
  { key: "Sehr hoch", icon: RoundRedIcon },
  { key: "Aktuell", icon: RoundBlackIcon },
  { key: "Prognose", icon: RoundYellowIcon },
];

type EpidemicPressureDevelopmentPropTypes = {
  selectedStationData: ISelectedStationData;
  chartsData: IChartsType;
  isLoading: boolean;
};

const EpidemicPressureDevelopment = ({
  selectedStationData,
  chartsData,
  isLoading,
}: EpidemicPressureDevelopmentPropTypes) => {
  const generateImageDate = (date: string): string => {
    let dt = "";
    const todaysDate = new Date(date);

    dt += todaysDate.getDate() + "/";
    dt += todaysDate.getMonth() + 1 + "/";
    dt += todaysDate.getFullYear();

    return dt;
  };

  return (
    <div className="epidemic-pressure-development-div">
      <div className="spray-heading-div text-center">
        <div className="spray-heading">Entwicklung des Epidemiedrucks</div>
        <div className="spray-subheading">
          Hier finden Sie Die Entwicklung des Epidemiedrucks an dem Monitoring-Standort, der Ihrer
          ausgewahlten Wetterstation am naschten liegt.
        </div>
      </div>

      <div className="epidemic-pressure-development-content-div">
        <div className="epidemic-pressure-weatherstation">
          <div className="epidemic-pressure-weatherstation-name">
            Wetterstation : {selectedStationData.weatherStationName}
          </div>
          <div className="epidemic-pressure-weatherstation-date ms-4">
            {generateImageDate(chartsData?.phytophthora?.date || chartsData?.alternaria.date)}
          </div>
        </div>
        <div className="epidemic-pressure-cards">
          <div className="epidemic-pressure-card">
            {isLoading ? (
              <div className="epidemic-pressure-loading-div">
                <Loader />
              </div>
            ) : (
              <>
                <div className="epidemic-pressure-card-title">Phytophthora</div>
                <img
                  src={`data:image/png;base64,${chartsData.phytophthora.image}`}
                  alt="phytophtora graph"
                  className="epidemic-pressure-graph-image"
                />
              </>
            )}
          </div>
          <div className="epidemic-pressure-card">
            {isLoading ? (
              <div className="epidemic-pressure-loading-div">
                <Loader />
              </div>
            ) : (
              <>
                <div className="epidemic-pressure-card-title">Alternaria</div>

                <img
                  src={`data:image/png;base64,${chartsData?.alternaria?.image}`}
                  alt="alternaria graph"
                  className="epidemic-pressure-graph-image"
                />
              </>
            )}
          </div>
        </div>
        <div className="epidemic-pressure-legend">
          <div className="epidemic-pressure-legend-title">Legende</div>
          <div className="epidemic-pressure-legend-row">
            {epidemicPressureLegends.map((lg, idx) => (
              <div className="epidemic-pressure-legend-item" key={idx}>
                <img src={lg.icon} alt={lg.key} />
                <span className="epidemic-pressure-item-label">{lg.key}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EpidemicPressureDevelopment);
