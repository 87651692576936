import React, { useEffect, useState } from "react";
import AccountActivationModal from "@/components/modals/accountActivation/AccountActivationModal";
import { useLocation, useParams } from "react-router-dom";

const AccountActivation = () => {
  const [openAccountActivationModal, setOpenAccountActivationModal] = useState(false);
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    setOpenAccountActivationModal(true);
  }, [location]);

  return (
    <AccountActivationModal
      openAccountActivationModal={openAccountActivationModal}
      setOpenAccountActivationModal={setOpenAccountActivationModal}
      firstName={params.firstName}
      lastName={params.lastName}
    />
  );
};

export default AccountActivation;
